/* Init */
var base = function () {
    "use strict";
    return {
        //main function
        init: function () {  

            // var section08Downloads = require('../images/section08/downloads/*.pdf');
            // var downoadUrl = section08Downloads["livro_fada"];

            $(window).load(function(){
                $('.loading').fadeOut();
                if($(window).width() > 767){
                    var vid = document.getElementById("videoFada");
                    var vid2 = document.getElementById("videoFadaMobile");
                    vid.play();
                    vid2.remove();
                } else {
                    var vid = document.getElementById("videoFada");
                    var vid2 = document.getElementById("videoFadaMobile");
                    vid2.play();
                    vid.remove();

                    window.addEventListener('touchstart', function videoStart() {
                      vid2.play();
                      console.log('first touch');
                      // remove from the window and call the function we are removing
                      this.removeEventListener('touchstart', videoStart);
                    });
                }

                $('.content').click(function(){
                    $(this).toggleClass('active');
                });

                function playVideo(){
                    console.log('play');
                    var formvid = document.getElementById("videoForm");
                    formvid.play();
                }

               /* $('#header .talk ').click(function(){
                    var vid = document.getElementById("videoFada");
                    vid.play();
                })*/

                var url = window.location.href
                var whats = "https://api.whatsapp.com/send?1=pt_BR&text="+url
                var facebook = "https://www.facebook.com/sharer/sharer.php?u="+url
                var twitter = "https://twitter.com/intent/tweet?url="+url
                $('.share-tw').attr('href',twitter);
                $('.share-fb').attr('href',facebook);
                $('.share-wa').attr('href',whats);

                /* Scroll Magic */
                var anchorsList = [];
                $('#fullpage .section').each(function(){
                    anchorsList.push($(this).attr('data-anchor'));
                });
                var talk = 0;
                var click = false;
                $('#menu a').click(function(){ 
                    click = true;
                   // $('.content').removeClass('active');
                });


                $('#fullpage').fullpage({
                    anchors: anchorsList,
                    autoScrolling: true,
                    verticalCentered: true,
                    onLeave : function( origin, destination, direction){
                        console.log('dest'+destination);
                    console.log('origin'+origin);
                        /*if(destination == 3 && origin == 4 && talk != 1){
                            talk = 0;
                        }*/
                        if(origin == 1){
                            $('#header .talk').addClass('hideTalk');
                        }
                        if(destination == 1){
                            $('#header .talk').removeClass('hideTalk');
                        }
                        if(destination == 7){
                            playVideo();
                        }
                       // console.log(click)  
                        if(origin == 3 && !click){
                            console.log('1');
                            if(talk == 0) {
                            console.log('2');
                                // permanece
                                $('#section01').addClass('talk2').removeClass('talk1');
                                talk = 1;
                                //return false;
                                $.fn.fullpage.setScrollingSpeed(0);
                                $.fn.fullpage.moveTo(3);
                                $.fn.fullpage.setScrollingSpeed(700); 
                            } else {
                                if(talk == 1){
                            console.log('3');
                                    // muda balão
                                    talk = 2;
                                    $('#section01').addClass('hideFada');
                                    //return false;
                                    $.fn.fullpage.setScrollingSpeed(0);
                                    $.fn.fullpage.moveTo(3);
                                    $.fn.fullpage.setScrollingSpeed(700);
                                } else {
                                    if(talk == 2){
                            console.log('4');
                                        // muda balão
                                        talk = 0;
                                        $('#section01').addClass('talk1').removeClass('talk2').removeClass('hideFada');
                                    }

                                }

                            }
                        }
                        if(destination == 10){

                            $('#section08 .owl-carousel').owlCarousel({
                                loop:true,
                                margin:0,
                                nav:true,
                                dots: false,
                                responsive:{
                                    0:{
                                        items:1
                                    }
                                }
                            });

                            $('#section08 .tab .owl-item a').click(function(e){
                                e.preventDefault();
                                var href = $(this).attr('href');
                                var preview = $(this).attr('data-preview');
                                if($(this).hasClass('livro')){
                                    preview = $(this).find('.hide').attr('src');
                                    console.log(preview);
                                }
                                console.log(href);
                                console.log(preview);
                                $('#section08 .expand img').attr('src',preview);
                                $('#section08 .expand').attr('data-href',href);
                            });
                            $('#section08 .tab:visible .owl-item.active a').click();

                        }
                        click = false;
                    }
                });  
                var controller = new ScrollMagic.Controller();

                $('#fullpage .section').each(function(){
                    var id = $(this).attr('id');
                    var anchor = $(this).attr('data-anchor');
                    new ScrollMagic.Scene({
                        triggerElement: '#'+id,
                        duration: '100%',
                        triggerHook: 0,
                        reverse: true,
                    })
                    .setClassToggle("a[href='#"+anchor+"']", 'link-active')
                    .addTo(controller);
                });  

                $('#section08 .tab').not(':first').hide();
                $('#section08 .tabs button').click(function(){
                    $('#section08 .tabs button').removeClass('active');
                    $(this).addClass('active');
                    $('#section08 .tab').hide();
                    $('#tab'+$(this).attr('data-tab')).show();
                    setTimeout(function(){
                        $('#section08 .tab:visible .owl-item.active a').click();
                    },500);
                });

                /* $('#section08 .items a').click(function(e){
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var name = $(this).find('img:first').attr('src').split('/').reverse()[0];
                    $('#section08 .download').attr('download',name).attr('href',href);
                    $('#section08 .expand img').attr('src',href);
                });*/
                function downloadURI(uri, name) {
                    var link = document.createElement("a");
                    link.download = name;
                    link.href = uri;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                //delete link;
                }


                $('#section08 .download').click(function(e){
                    e.preventDefault();
                    console.log('foi');
                    var url = window.location.origin + window.location.pathname + $('.expand').attr('data-href');
                    var name = $('.expand').attr('data-href').split('/').reverse()[0];
                    downloadURI(url,name);
                });


                /* Section 1 */
                /*$('#section01 .video2').hover(function(){
                    console.log('hover');
                    $('#section01 .fada').stop(true).animate({top: -600}, 1000, function(){
                        $('#section01 .fada').addClass('inverse'); 
                        $('#section01 .fada').stop(true).animate({top: 80},1000); 
                    });
                }, function(){
                    $('#section01 .fada').stop(true).animate({top: -600}, 1000, function(){
                        $('#section01 .fada').removeClass('inverse'); 
                        $('#section01 .fada').stop(true).animate({top: 80},1000); 
                    });
                });*/

                /* Section 5 */
                $('#section05 .step1 a').click(function(e){
                    e.preventDefault();
                    $(this).closest('.step').hide().next().fadeIn();
                });

                $('#section05 .step2 form').submit(function(e){
                    e.preventDefault();
                    $('#section05 .step2 form input').removeClass('error');
                    $('#section05 .step2 form input').each(function(){
                        if($(this).val() == ''){
                            $(this).addClass('error');
                        } 
                    });
                    if($('#section05 .step2 form input.error').length < 1){
                        $(this).closest('.step').hide().next().fadeIn();
                    }
                });

                function resize(){
                    var ww = $(window).width();
                    if(ww > 767){
                        var vw = $('.bgvideo #videoFada').width();
                        var offvw = $('.bgvideo #videoFada').offset().left
                        if(ww < vw){
                            vw = ww
                            offvw = 0
                        }
                        $('.align').css('width',vw); 
                        $('.content').css('left', offvw + vw - 30);
                    } else {
                        var vw = $('.bgvideo #videoFadaMobile').width();
                        var offvw = $('.bgvideo #videoFadaMobile').offset().left
                        if(ww < vw){
                            vw = ww
                            offvw = 0
                        }
                        $('.align').css('width',vw); 
                        $('.content').css('left', offvw + vw - 30);                     
                    }
                    $('#section08 .owl-carousel').owlCarousel({
                        loop:true,
                        margin:0,
                        nav:true,
                        dots: false,
                        responsive:{
                            0:{
                                items:1
                            }
                        }
                    });
                }
                resize();

                $(window).resize(function(){
                    setTimeout(function(){
                        resize();
                    },2000);
                });
            });
        }
    }
}();

$(document).ready(function() {
    base.init();
});